<template>
  <div ref="myElement">
    <!-- Titlebar
    ==================================================-->
    <div class="single-page-header"
      v-if="(item.userId > 1 && isMobile) || (!isSingeJobLoaded && !isResultPage) || !isResultPage"
      data-background-image="images/single-job.jpg">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadCrumbsSingleJob">
              <Breadcrumbs v-if="!isResultPage && isMobile" />
            </div>

            <div class="single-page-header-inner">
              <div class="left-side">
                <div class="header-image">
                  <CompanyLogo :logo="item.logo" :width="imgWidth" :anonymous="item.anonymous" />
                </div>

                <div class="header-details">
                  <a-skeleton :title="false" :paragraph="{ rows: 2 }" :loading="!isSingeJobLoaded && !isResultPage"
                    active>
                  </a-skeleton>
                  <h3 v-if="!isResultPage">
                    {{ item.title | truncate(100) | stripHTML }}

                  </h3>
                  <h5 v-if="showCompanyName()">About the Employer</h5>
                  <ul v-if="showCompanyName()">
                    <li>
                      <i class="icon-material-outline-business"></i> {{ item.companyName || item.userName }}
                    </li>
                    <!-- <li>
                      <div class="star-rating" data-rating="4.9"></div>
                    </li> -->
                    <!-- <li>
                      <img class="flag" src="images/flags/gb.svg" alt>
                      {{ item.stateName }}
                    </li> -->
                    <li>
                      <div class="verified-badge-with-title">Verified</div>
                    </li>
                  </ul>
                </div>

              </div>
              <div class="right-side">
                <div class="salary-box" v-if="item.minSalary > 0">
                  <div class="salary-type">{{ item.minSalary > 1000 ? "Annual Salary" : "Hourly Rate" }}</div>
                  <div class="salary-amount">${{ item.minSalary | formatSalary }} - ${{ item.maxSalary | formatSalary }}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <!-- Page Content
    ==================================================-->

    <div class="container margin-top-20">
      <div class="breadCrumbsSingleJob">
        <div class="col-lg-12">
          <Breadcrumbs v-if="!isResultPage && !isMobile" />
        </div>
      </div>


      <div class="row">
        <!-- Content -->
        <div class="col-xl-8 col-lg-8 content-right-offset">

          <div class="single-page-section">
            <h3 class="margin-bottom-25">Job Description</h3>
            <a-skeleton :title="false" active :loading="!isSingeJobLoaded && !isResultPage">
              <p v-html="item.description"></p>
            </a-skeleton>
          </div>

          <!-- Skills -->
          <div class="single-page-section" v-if="item.skills">
            <h3>Skills Required</h3>
            <div class="task-tags">
              <span v-for="skill in skillsList" :key="skill">{{ skill }}</span>
            </div>
          </div>
          <div class="clearfix"></div>

          <!-- Expereince -->
          <div class="single-page-section" v-if="item.experienceYears">
            <h3>Experience Required</h3>
            <p>
              {{ getExperienceText(item.experienceYears) }}
            </p>
          </div>
          <div class="clearfix"></div>
          <div class="single-page-section">
            <Banner v-if="!isResultPage || !isMobile"></Banner>
            <ShareIt :itemId="item.id" />
          </div>
        </div>

        <!-- Sidebar -->
        <div class="col-xl-4 col-lg-4">
          <div class="sidebar-container">
            <!-- <a href="#small-dialog" class="apply-now-button popup-with-zoom-anim">
              Apply Now
              <i class="icon-material-outline-arrow-right-alt"></i>
            </a> -->
            <a v-if="item.email" data-toggle="modal" @click="openModal" :data-target="'#resumeModal' + item.id"
              class="apply-now-button popup-with-zoom-anim">
              Apply Now
              <i class="icon-material-outline-arrow-right-alt"></i>
            </a>
            <a v-else-if="item.link" :href="item.link" target="_blank" class="apply-now-button popup-with-zoom-anim">
              Apply Now
              <i class="icon-material-outline-arrow-right-alt"></i>
            </a>
            <!-- display button while loading so it will not be loaded later -->
            <a v-if="!isSingeJobLoaded && !isResultPage" class="apply-now-button popup-with-zoom-anim">
              Apply Now
              <i class="icon-material-outline-arrow-right-alt"></i>
            </a>

            <!-- Sidebar Widget -->
            <div class="sidebar-widget" v-if="!isResultPage || !isMobile">
              <div class="job-overview">
                <div class="job-overview-headline">Job Summary</div>
                <div class="job-overview-inner">
                  <ul>
                    <li>
                      <i class="icon-material-outline-location-on"></i>
                      <span>Location</span>
                      <h5 v-if="item.cityName">{{ item.cityName.toLowerCase() === 'other' ?
        (item.stateName.toLowerCase() === 'other' ?
          'N/A' : item.stateName) : item.cityName }}</h5>
                    </li>
                    <li>
                      <i class="icon-material-outline-business-center"></i>
                      <span>Job Type</span>
                      <h5>
                        <span v-if="item.fullTime == 1">Full Time</span>
                        <span v-if="item.partTime == 1">Part Time</span>
                        <span v-if="item.hybrid == 1">Hybrid</span>
                        <span v-if="item.temporary == 1">Temporary</span>
                        <span v-if="item.fromHome == 1">Remote</span>
                        <span v-if="item.freelance == 1">Freelance</span>
                      </h5>
                    </li>
                    <li v-if="item.minSalary > 0">
                      <i class="icon-material-outline-local-atm"></i>
                      <span>Salary</span>
                      <h5>${{ item.minSalary | formatSalary }}- ${{ item.maxSalary | formatSalary }}
                        <div class="salary-type">{{ item.minSalary > 1000 ? "Annual Salary" : "Hourly Rate" }}</div>

                      </h5>
                    </li>
                    <li>
                      <i class="icon-material-outline-access-time"></i>
                      <span>Date Posted</span>
                      <h5>{{ item.date | formatDate }}</h5>
                    </li>
                    <li>
                      <i class="icon-feather-hash"></i>
                      <span>Item Id</span>
                      <h5>{{ item.id }}</h5>
                    </li>


                    <!-- <li>
                      <a>
                        View {{showItem?"Less":"More"}}
                        <i class="icon-feather-arrow-right"></i>
                      </a>
                    </li>-->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" :id="'resumeModal' + item.id" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div id="small-dialog" class="zoom-anim-dialog dialog-with-tabs">
            <!--Tabs -->
            <div class="sign-in-form">

              <ul class="popup-tabs-nav" style="pointer-events: none;">
                <li class="active"><a href="#tab">Apply Now</a></li>
              </ul>

              <div class="popup-tabs-container">
                <ApplyNow :key="`apply-now-${item.id}`" v-if="this.$auth.user && showApplyNow" :item="item"
                  @sent="closeModal">
                </ApplyNow>
                <LogInBase :whithinPopUp="true" v-if="!this.$auth.user" :userType="1"></LogInBase>
              </div>

            </div>
            <button title="Close (Esc)" type="button" @click="closeModal" class="mfp-close close" data-dismiss="modal"
              aria-label="Close"></button>
          </div>

        </div>

      </div>
    </div>
  </div>
  <!-- Apply for a job popup / End -->
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ShareIt from "./ShareIt.vue";
import ApplyNow from "./ApplyNowDialog.vue";
import LogInBase from "../login/LogInBase.vue";
import Breadcrumbs from "./Breadcrumbs.vue";
import CompanyLogo from '~/components/base/CompanyLogo';
import Banner from '~/components/jobs/Banner';


// import VS2 from 
export default {
  name: "SingleJob",
  components: {
    Banner
  },
  props: {
    itemResult: {
      default: function () {
        return {};
      }
    }
  },
  data: function () {
    return {
      imgWidth: 100,
      magnificPopup: {},
      showApplyNow: false
    }
  },

  watch: {
    isSingeJobLoaded(newValue) {
      //client side 
      console.log("isSingeJobLoaded: " + newValue);
      if (newValue === true) {
        // Perform the action after data is fetched
        this.doUpdateStatistics();
      }
    }
  },

  computed: {
    ...mapGetters({
      storeItem: "jobs/singleJob",
      isMobile: "isMobile",
      isSingeJobLoaded: "jobs/isSingeJobLoaded"
    }),
    item() {
      // If itemResult has data, use it; otherwise, fallback to storeItem
      return Object.keys(this.itemResult).length > 0 ? this.itemResult : this.storeItem;
    },
    skillsList() {
      // Split the skills string into an array
      return this.item.skills.split(',');
    },
    isResultPage() {
      // Check if the component is being used in a results page
      return this.itemResult.title;
    },

  },
  mounted: function () {
    // this.magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable

    if (this.isMobile) {
      this.imgWidth = 50;
      this.$refs.myElement.scrollIntoView();
    }
    //client side full single page (not results page)
    if (this.item.id)
      this.doUpdateStatistics();


  },
  created() {
    //console.log(this.itemResult);
    // if (this.itemResult) {
    //   this.setSingleJob(this.itemResult);
    //Object.assign(this.item, this.itemResult);
    //}
  },
  beforeDestroy() {
    this.$store.commit("jobs/setSingleJobLoaded", false);
  },
  methods:
  {
    ...mapMutations({
      setSingleJob: "jobs/setSingleJob"
    }),
    ...mapActions({
      updateStatistics: "jobs/updateStatistics"
    }),
    showCompanyName() {
      return (this.item.companyName || this.item.userName) && !(this.item.anonymous) && !(this.item.userName == 'robot');
    },

    displayLocation() {

      var cityName = this.item.cityName;
      var stateName = this.item.stateName;
      if (cityName.toLowerCase() === 'other') {
        if (stateName.toLowerCase() === 'other') {
          return 'N/A';
        } else {
          return stateName;
        }
      } else {
        return cityName;
      }
    },

    getExperienceText(experienceYears) {
      if (experienceYears === 2) {
        return "1-2 years";
      } else if (experienceYears === 4) {
        return "3-4 years";
      } else if (experienceYears === 6) {
        return "5-6 years";
      } else if (experienceYears === 7) {
        return "7 and up";
      } else {
        return "Experience not specified";
      }
    },
    closeModal() {
      this.showApplyNow = false;

      // this.magnificPopup.close();
      //$('.modal-dialog').hide(1000);
      $('.mfp-close').click();

    },
    openModal() {
      this.showApplyNow = true;

    },
    async doUpdateStatistics() {
      try {
        var item = {
          itemId: this.item.id,
          type: "view"
        };
        this.updateStatistics({
          data: item
        })
          .then((response) => {
            // console.log(response);
          });

      } catch (e) {
        self.$sentry.captureException(e);

      }

    },
  },
  components: { ShareIt, ApplyNow, LogInBase, Breadcrumbs, CompanyLogo, Banner }
};
</script>
