<template>
  <!-- <div class="col-lg-12"> -->
  <nav id="breadcrumbs" class="dark">
    <ul>
      <li v-for="item in breadCrumbs">
        <NuxtLink :to="item.url">{{ item.name }}</NuxtLink>
      </li>
    </ul>
  </nav>

  <!-- </div> -->
</template>
<script>

import { mapGetters } from "vuex";
export default {
  name: "Breadcrumbs",
  data: function () {
    return { baseUrl: process.env.baseUrl };

  },
  computed: {
    ...mapGetters({
      breadCrumbs: "jobs/breadCrumbs",
    }),

  }
}
</script>
