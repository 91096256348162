<template>
    <!-- Share Buttons -->
    <div class="share-buttons margin-top-25">
        <div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
        <div class="share-buttons-content">
            <span>Interesting? <strong>Share It!</strong></span>
            <ul class="share-buttons-icons">
                <li><a @click.prevent="share('facebook')" data-button-color="#3b5998" title="Share on Facebook"
                        data-tippy-placement="top"><i class="icon-brand-facebook-f"></i></a></li>
                <li><a @click.prevent="share('twitter')" data-button-color="#1da1f2" title="Share on Twitter"
                        data-tippy-placement="top"><i class="icon-brand-twitter"></i></a></li>
                <li><a @click.prevent="share('whatsapp')" href="#" data-button-color="#00E676" title="Share on WhatsApp"
                        data-tippy-placement="top"><i class="icon-brand-whatsapp"></i></a></li>
                <li><a @click.prevent="share('linkedin')" data-button-color="#0077b5" title="Share on LinkedIn"
                        data-tippy-placement="top"><i class="icon-brand-linkedin-in"></i></a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    props: {
        itemId: {
            default: function () {
                return {};
            }
        }
    },
    data: function () {
        return {
            socialShare: [{
                platform: "facebook", link: "https://www.facebook.com/sharer/sharer.php?u="
            },
            {
                platform: "twitter", link: "http://twitter.com/share?url="
            },
            {
                platform: "whatsapp", link: "https://api.whatsapp.com/send?text="
            },
            {
                platform: "linkedin", link: "https://www.linkedin.com/shareArticle?mini=true&url="
            }]
        }
    },

    mounted: function () {
        $(".share-buttons-icons a").each(function () {
            var buttonBG = $(this).attr("data-button-color");
            if (buttonBG !== undefined) {
                $(this).css("background-color", buttonBG);
            }
        });
        tippy("[data-tippy-placement]", {
            delay: 100,
            arrow: true,
            arrowType: "sharp",
            size: "regular",
            duration: 200,

            // 'shift-toward', 'fade', 'scale', 'perspective'
            animation: "shift-away",

            animateFill: true,
            theme: "dark",

            // How far the tooltip is from its reference element in pixels
            distance: 10,
        });
    },
    methods: {
        ...mapActions("jobs",
            ["updateStatistics"]
        ),
        share(platform) {
            this.doUpdateStatistics();
            var shareUrl = this.socialShare.filter(x => x.platform == platform)[0].link;
            var url = "https://macherusa.com" + this.$route.fullPath + "/item/item/" + this.itemId;
            const navUrl = shareUrl + url;
            window.open(navUrl, '_blank');
        },
        async doUpdateStatistics() {
            var self = this;
            try {
                var item = {
                    itemId: this.itemId,
                    type: "social"
                };
                this.updateStatistics({
                    data: item
                })
                    .then((response) => {
                        // console.log(response);


                    });

            } catch (e) {
                self.$sentry.captureException(e)
            }
        },
    }
}
</script>