<template>
    <!-- Tab -->
    <div class="popup-tab-content apply-now" id="tab" style="">

        <!-- Welcome Text -->
        <div class="welcome-text">
            <h3>Apply For: {{ item.title }}</h3>
        </div>
        <div class="margin-bottom-15" v-if="appliedDate">
            You applied for this job on {{ appliedDate | formatDate }}
        </div>
        <!-- Form -->
        <form method="post" id="apply-now-form" @submit.prevent="applyNow">

            <div class="input-with-icon-left">
                <i class="icon-material-outline-account-circle"></i>
                <input v-model="$v.name.$model" type="text" class="input-text with-border" name="name" id="name"
                    placeholder="First and Last Name">
                <p class="text-danger" v-if="$v.name.$dirty && !$v.name.required">
                    Name is required.
                </p>


            </div>

            <div class="input-with-icon-left">
                <i class="icon-material-baseline-mail-outline"></i>
                <input v-model.trim="$v.email.$model" type="text" class="input-text with-border" name="emailaddress"
                    id="emailaddress" placeholder="Email Address">
                <p class="text-danger" v-if="$v.email.$dirty && !$v.email.required">
                    Email is required.
                </p>
                <p class="text-danger" v-if="$v.email.$dirty && !$v.email.email">
                    Please enter a valid email.
                </p>
            </div>

            <div class="input-with-icon-left">
                <textarea v-model="coverletter" type="text" class="input-text with-border margin-bottom-35"
                    name="coverletter" id="coverletter" placeholder="Cover Letter"></textarea>

            </div>


            <div class="uploadButton">
                <input @change="handleChange" class="uploadButton-input" type="file" accept="application/pdf,.doc,.docx"
                    id="resumeUploader">
                <label class="uploadButton-button ripple-effect" for="resumeUploader">
                    {{ resumeFile ? "Change File" : "Upload File" }}
                </label>
                <span class="uploadButton-file-name">
                    <span v-if="resumeFile">
                        {{ resumeFile.fileName }}
                    </span>
                    <span v-if="!resumeFile">
                        Max.
                        file size: 50
                        MB.
                    </span>
                </span>
                <!-- Error Message for Resume File -->
                <p class="text-danger applynow-error" v-if="resumeFileRequiredError">
                    Uploading a resume file is required.
                </p>
            </div>

        </form>

        <!-- Button -->
        <button class="button margin-top-35 full-width button-sliding-icon ripple-effect" type="submit"
            form="apply-now-form">Apply Now <i class="icon-material-outline-arrow-right-alt"></i></button>

    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ACTIONS } from "../../store";
import { required, email } from "vuelidate/lib/validators";

import axios from "axios";
import {
    uploadFile
} from "../../services/api/auth";

export default {
    props: {
        item: {
            default: function () {
                return {};
            }
        }
    },

    data() {
        return {
            userId: this.$auth.user.userId,
            name: this.$auth.user.name,
            email: this.$auth.user.email,
            coverletter: "",
            appliedDate: null,
            baseUrl: process.env.baseUrl,
            resumeFileRequiredError: false
        }
    },
    validations: {
        name: { required },
        email: { required, email }
    },
    mounted() {
        this.getResumeFile();
        if (this.item.id)
            this.getAppliedDate();
    },
    computed: {
        ...mapGetters({
            resumeFile: "resumeFileToApply",
            isSingeJobLoaded: "jobs/isSingeJobLoaded"
        })
    },

    watch: {

        isSingeJobLoaded(newValue) {
            //client side 
            if (newValue === true) {
                // Perform the action after data is fetched
                this.getAppliedDate();
            }
        }
    },

    methods: {
        ...mapActions({
            getResumeFiles: ACTIONS.GET_RESUME_FILES,
            deleteResumeFile: ACTIONS.DELETE_RESUME_FILE,
            getUserAppliedDate: ACTIONS.GET_USER_APPLIED_DATE,
            setResumeFileToApply: ACTIONS.SET_RESUME_FILE_TO_APPLY
        }),
        ...mapActions("jobs",
            ["insertJobApplicant",
                "updateStatistics"]
        ),

        applyNow(event) {
            this.$v.$touch();
            // Check if the resume file is uploaded
            if (!this.resumeFile || this.resumeFile == 0) {
                this.resumeFileRequiredError = true;
                return;
            } else {
                this.resumeFileRequiredError = false;
            }


            if (this.$v.$error) {
                return;
            }

            try {
                //disabled button so user will not double click on it and multiple send resumes
                const element = event.submitter;
                element.classList.add('disabled-button');
                element.setAttribute('disabled', 'true');
                element.onclick = (e) => e.preventDefault();
            }
            catch (e) {

            }
            // const today = new Date().toDateString();
            // const appliedDateDate = new Date(this.appliedDate).toDateString();


            // if (today == appliedDateDate) {
            //     this.$toast.global.appliedAlready();
            //     return;
            // }

            this.apply();
            this.sendResume();
            this.doUpdateStatistics();

        },
        async apply() {
            var self = this;
            try {
                $nuxt.$root.$loading.start();
                var applicant = {
                    jobId: this.item.id,
                    jobApplicantUserId: this.userId,
                    jobApplicantStatusId: 11,
                };
                self.insertJobApplicant({
                    applicant: applicant
                })
                    .then((response) => {
                        $nuxt.$root.$loading.finish();

                    });

            } catch (e) {
                self.$sentry.captureException(e);
                $nuxt.$root.$loading.finish();
                this.error = e.response
                    ? e.response.data.message
                    : "There was an error applying for the position.";
            }


        },
        async doUpdateStatistics() {
            var self = this;
            try {
                var item = {
                    itemId: this.item.id,
                    type: "email"
                };
                this.updateStatistics({
                    data: item
                })
                    .then((response) => {

                    });

            } catch (e) {
                self.$sentry.captureException(e);

            }

        },
        generateToken() {

            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let token = '';
            for (let i = 0; i < 20; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                token += characters[randomIndex];
            }
            return token;
        },
        async sendResume() {


            var self = this;
            const token = this.generateToken();
            const fileUrl = `${process.env.baseUrl}/resume/${this.resumeFile.uuid}`;
            const activateUrl = `${process.env.baseUrl}/activatead?jobId=${this.item.id}&token=${token}`;
            const message = {
                to: this.item.email,
                //to: "diti.pn@macherusa.com",
                replyTo: {
                    email: this.email,
                    name: this.name
                },

                dynamic_template_data: {
                    itemId: this.item.id,
                    title: this.item.title,
                    activateUrl: activateUrl,
                    candidate: {
                        email: this.email,
                        name: this.name || 'A candidate',
                        coverLetter: this.coverletter ? `Cover Letter: ${this.coverletter}` : "",
                        resumeFileUrl: fileUrl,
                        resumeFileUuid: this.resumeFile.uuid,
                    }
                },

                template_id: "d-f87f63c679834b0481cebc233ac829a3"
                //template_id: "d-26c785c2bfa0478b87888ebbaa1de7e4"
            };
            if (this.item.userId == 1)
                message.dynamic_template_data.robotAd = 1;
            var self = this;
            $nuxt.$root.$loading.start();
            return await this.$mailer
                .sendEmail(message)
                .then(response => {
                    if (response.error)
                        self.$toast.global.wentWrong();
                    else
                        self.$toast.global.resumeSent();
                    $nuxt.$root.$loading.finish();
                    setTimeout(function () {
                        self.$emit('sent');
                    }, 1000)
                })
                .catch((e) => {
                    self.$sentry.captureException(e);
                    self.$toast.global.wentWrong();
                    $nuxt.$root.$loading.finish();
                });
        },
        getResumeFile() {
            var userId = this.$auth.user.userId;
            return this.getResumeFiles(
                { userId }
            )
                .then(({ recordset: resumeFiles }) => {
                    if (resumeFiles.length > 0) {
                        var defaultFile = resumeFiles.filter(x => x.is_default == "true")[0];
                        this.setResumeFileToApply(defaultFile);
                        // this.resumeFile = deafultFile;
                    }
                    else//set default file to null
                        this.setResumeFileToApply();

                });
        },
        getAppliedDate() {
            var applicant = {
                jobId: this.item.id,
                jobApplicantUserId: this.userId
            }
            var self = this;
            return this.getUserAppliedDate(applicant)
                .then((data) => {
                    if (data.length > 0) {
                        self.appliedDate = data[0].date;
                    }
                    else
                        self.appliedDate = null;

                });
        },
        handleChange(e) {
            this.resumeFileRequiredError = false;

            var self = this;
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            var file = files[0];
            const [extension, ...name] = file.name.split(".").reverse();
            //const fileName = name.reverse().join(".");
            if (!this.beforeUploadResume(file))
                return false;

            const data = new FormData();
            data.append("resume", file);
            data.append("user", this.userId);
            data.append("fileName", file.name);
            $nuxt.$root.$loading.start();
            uploadFile(data, {
                params: {
                    user: this.userId,
                    fileName: `${this.userId}.${extension}`,
                    name: file.name,
                },
            }).then((data) => {
                //resume was existed so it needs to be deleted in order to exchange it with the new one
                if (self.resumeFile)
                    self.deleteResume(self.resumeFile);
                var resumes = data.data.recordsets[0]
                if (resumes.length > 0) {
                    var defaultFile = resumes.filter(x => x.is_default == "true")[0];
                    self.setResumeFileToApply(defaultFile);
                }
                $nuxt.$root.$loading.finish();
                self.$toast.global.resumeUploadSucessful();

            }
            ).catch((e) => {
                self.$sentry.captureException(e);
                self.$toast.global.resumeUploadFailed();
            });

        },
        deleteResume(file) {
            var self = this;
            $nuxt.$root.$loading.start();
            this.deleteResumeFile(file).then(() => {
                $nuxt.$root.$loading.finish();
            });
        },
        beforeUploadResume(file) {
            const isLt10M = file.size / 1024 / 1024 < 10;
            const [extension] = file.name.split(".").reverse();
            const extensions = ["pdf", "doc", "docx"];

            const isValidDoc = extensions.some(el => extension.includes(el));
            if (!isLt10M) {
                this.$toast.global.resumeSize();
            }
            if (!isValidDoc)
                this.$toast.global.resumeFormat();
            return isLt10M && isValidDoc;
        },
        async createBase64File(urlFile) {
            const response = await axios.get(urlFile, { responseType: 'blob' });
            const blob = response.data;

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result.split(',')[1]; // Extract base64 string without headers
                    resolve(base64data);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },

    }
}
</script>
<style>
.applynow-error {
    margin: 10px 0 0 0 !important;
}

.button-sliding-icon:disabled {
    pointer-events: none;
    /* This is actually redundant for disabled buttons, as they don't receive pointer events by default */
    cursor: not-allowed;
    opacity: 0.5;
    /* Adjust opacity to make the button appear "disabled" */

}
</style>