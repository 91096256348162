<template>
    <div>
        <iframe v-if="!isMobile" :src="getAdIframeSrc()" frameborder="0" scrolling="no" width="410" height="100"></iframe>
        <iframe v-if="isMobile" :src="getAdIframeSrc()" frameborder="0" scrolling="no" width="320" height="100"></iframe>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'Banner',

    data: function () {
        return {
            reviveId: process.env.REVIVE_BANNER_ID,
            zoneId: process.env.REVIVE_BANNER_ZONE_ID,
            mobileZoneId: process.env.REVIVE_BANNER_MOBILE_ZONE_ID
        };
    },
    computed: {
        ...mapGetters({
            isMobile: "isMobile"
        })
    },

    methods: {
        getAdIframeSrc() {
            const randomNum = Math.floor(Math.random() * 99999999999);
            var zoneId = this.isMobile ? this.mobileZoneId : this.zoneId;
            return `https://servedby.revive-adserver.net/afr.php?zoneid=${zoneId}&cb=${randomNum}`;
        },
    }
};
</script>
